var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","size":_vm.getSize,"title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.parent)?_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Добавляется в эту категорию'))+": "),_c('b',[_vm._v(_vm._s(_vm.parent.name_uz))])]):_vm._e(),(_vm.parent && _vm.parent.level == 1)?[(!_vm.noBrand)?_c('b-form-group',{attrs:{"label":_vm.$t('Brend'),"label-for":"brand_id"}},[_c('validation-provider',{attrs:{"name":"brand_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"brand_id","state":errors.length > 3 ? false : null,"options":_vm.brandsList.data,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('Mahsulot brendini tanlang'),"label":"name"},model:{value:(_vm.form.brand_id),callback:function ($$v) {_vm.$set(_vm.form, "brand_id", $$v)},expression:"form.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.noBrand),callback:function ($$v) {_vm.noBrand=$$v},expression:"noBrand"}},[_vm._v(" Brend mavjud emas ")]),(_vm.noBrand)?_c('b-form-group',{attrs:{"label":_vm.$t('Kategoriya nomi'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":"Kategoriya nomi"},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]:(_vm.parent && _vm.parent.level == 2)?[_c('b-form-group',{attrs:{"label":_vm.$t('Mahsulot nomi'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":"Mahsulot nomi"},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_c('b-form-group',{attrs:{"label":_vm.$t('Nomi'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":""},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),((_vm.parent && _vm.parent.level == 3) || ((_vm.parent && _vm.parent.level == 2) && !_vm.isEdit))?_c('b-form-group',{attrs:{"label":"Boshlang'ich to'lov (USD)","label-for":"in_advance_payment_amount"}},[_c('validation-provider',{attrs:{"name":"in_advance_payment_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"in_advance_payment_amount","state":errors.length > 0 ? false : null,"options":_vm.cleaveOption,"placeholder":"0"},model:{value:(_vm.form.in_advance_payment_amount),callback:function ($$v) {_vm.$set(_vm.form, "in_advance_payment_amount", $$v)},expression:"form.in_advance_payment_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }