<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :size="getSize"
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="save"
      >
        <div
          v-if="parent"
          class="mb-2"
        >
          <!-- Ushbu turga qo'shiladi -->
          {{ $t('Добавляется в эту категорию') }}: <b>{{ parent.name_uz }}</b>
        </div>
        <template v-if="parent && parent.level == 1">
          <b-form-group
            v-if="!noBrand"
            :label="$t('Brend')"
            label-for="brand_id"
          >
            <validation-provider
              #default="{ errors }"
              name="brand_id"
              rules="required"
            >
              <v-select
                id="brand_id"
                v-model="form.brand_id"
                :state="errors.length > 3 ? false : null"
                :options="brandsList.data"
                :reduce="(option) => option.id"
                :placeholder="$t('Mahsulot brendini tanlang')"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-checkbox
            v-model="noBrand"
            class="mb-2"
            :value="true"
            :unchecked-value="false"
          >
            Brend mavjud emas
          </b-form-checkbox>
          <b-form-group
            v-if="noBrand"
            :label="$t('Kategoriya nomi')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name_uz"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name_uz"
                :state="errors.length > 3 ? false : null"
                name="name"
                placeholder="Kategoriya nomi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>

        <template v-else-if="parent && parent.level == 2">
          <b-form-group
            :label="$t('Mahsulot nomi')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name_uz"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name_uz"
                :state="errors.length > 3 ? false : null"
                name="name"
                placeholder="Mahsulot nomi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <b-form-group
          v-else
          :label="$t('Nomi')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="name_uz"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="form.name_uz"
              :state="errors.length > 3 ? false : null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--  -->
        <b-form-group
          v-if="(parent && parent.level == 3) || ((parent && parent.level == 2) && !isEdit)"
          label="Boshlang'ich to'lov (USD)"
          label-for="in_advance_payment_amount"
        >
          <validation-provider
            #default="{ errors }"
            name="in_advance_payment_amount"
            rules="required"
          >
            <cleave
              id="in_advance_payment_amount"
              v-model="form.in_advance_payment_amount"
              class="form-control"
              :state="errors.length > 0 ? false : null"
              :options="cleaveOption"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- {{ parent }} -->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import GoodTableSelectable from '@/views/table/vue-good-table/GoodTableSelectable.vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, vSelect, GoodTableSelectable, Cleave,
  },
  data() {
    return {
      form: {
        id: null,
        parent_id: null,
        name_uz: null,
        brand_id: null,
        product_category_ids: null,
        unit_of_measure_id: 1,
        in_advance_payment_amount: null,
      },
      filter: {
        per_page: 9999,
        total: 0,
        page: 1,
        name_uz: null,
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        warehouse_id: null,
        is_cost_price: null,
        category_name: '',
        suffix_name: '',
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      noBrand: false,
      products: {},
      warehouseProducts: [],
      ids: null,
      parent: null,
      title: '',
      visible: false,
      brandsList: {},
      loading: false,
      isEdit: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ unit_of_measures: 'resource/UNIT_OF_MEASURES' }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('ID'),
          field: 'id',
          isAmount: true,
        },
        {
          label: this.$t('Nomi'),
          field: 'name_uz',
        },
      ]
    },
    getSize() {
      if (this.parent && this.parent.level == 2) {
        return 'lg'
      }
      return ''
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          this.parent = null
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.parent_id ? this.$t('Добавить тип продукта') : this.$t('Добавить категория продукта')
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.parent = item
      this.form.id = item.id
      this.form.in_advance_payment_amount = item.in_advance_payment_amount
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.form.unit_of_measure_id = item.unit_of_measure_id
      this.visible = true
      this.isEdit = true
    },
    add(item) {
      this.form.parent_id = item.id
      this.form.in_advance_payment_amount = item.in_advance_payment_amount
      this.parent = item
      this.isEdit = false
      this.visible = true
      this.unitOfMeasures()
      this.getBrandsList()
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    getBrandsList() {
      this.getBrands({ per_page: 9999 }).then(res => {
        this.brandsList = res.data
      })
    },
    //

    selectedRows(rows) {
      this.form.product_category_ids = ''
      // const setIds = []
      if (rows && rows.length) {
        console.log(6666, rows)
        this.form.product_category_ids = rows.map(el => el.id)
      }
    },
    ...mapActions({
      getCategories: 'productCategory/index', store: 'productCategory/store', update: 'productCategory/update', unitOfMeasures: 'resource/unitOfMeasures', getBrands: 'resource/indexBrands',
    }),
  },
}
</script>

<style scoped></style>
